import React from 'react'
import styled from "styled-components"
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS } from '@contentful/rich-text-types'

import Seo from "../components/Seo"
import PageTitle from '../components/PageTitle'
import NewsletterCTA from '../components/NewsletterCTA'

import Facebook from '../images/icon-facebook-sharing.svg'
import Twitter from '../images/icon-twitter-sharing.svg'
import Email from '../images/icon-email-sharing.svg'

const EventContainer = styled.section`
  margin-bottom: 120px;

  .event-featured-image {
    margin: 0 auto 40px;
    width: 100%;
    max-width: 1200px;
    height: auto;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      background-image: linear-gradient(225deg, #000000 0%, rgba(0,0,0,0.00) 47%, #000000 100%);
    }
  }

  .container {
    max-width: 800px;
    margin: 0 auto;

    .event-meta {
      text-align: center;
      margin-bottom: 40px;

      p {
        color: var(--pink);
        font-size: 19px;
        font-weight: var(--font-bold);
        margin-bottom: 10px;

        a {
          color: var(--pink);
        }

        span {
          font-size: 20px;
          color: var(--white);
          margin-right: 12px;
        }
      }
    }

    .event-body {
      margin-bottom: 50px;

      .intro-container {
        display: flex;
        align-items: center;
        column-gap: 50px;
        row-gap: 30px;
        width: 100%;
        margin-bottom: 50px;
  
        p {
          margin-bottom: 0;
        }
  
        .intro-photo {
          position: relative;
          max-width: 168px;
          min-width: 168px;
          width: 100%;
      
          .bio-frame-photo,
          .bio-frame-back,
          .bio-frame-front {
            width: 100%;
            max-width: 160px;
            height: auto;
            margin-bottom: 0;
          }
      
          .bio-frame-photo,
          .bio-frame-front {
            position: absolute;
            top: 8px;
            left: 8px;
          }
        }
      }

      p {
        color: var(--gray);

        a {
          color: var(--pink);
        }
      }
  
      .gatsby-image-wrapper {
        margin-bottom: 30px;
      }
  
      blockquote {
        margin: 0 20px 20px;
  
        p {
          margin-bottom: 30px;
          color: var(--white);
          font-size: 20px;
          font-style: italic;
        }
      }
    }
  }

  .event-sharing {
    text-align: center;
    margin-bottom: 55px;

    p {
      color: var(--pink);
      font-size: 23px;
      font-weight: var(--font-bold);
      margin-bottom: 15px;
    }

    .sharing-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
  }

  .view-more-btn {
    text-align: center;
  }
`

function Event({ pageContext }) {
  const { event } = pageContext
  
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [MARKS.ITALIC]: text => <i>{text}</i>,
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { gatsbyImageData } = node.data.target
        if (!gatsbyImageData) {
          return null
        }
        return <GatsbyImage image={gatsbyImageData} alt={node.data.target.title} />
      },
    },
  }

  return (
    <main>
      <PageTitle title={event.title} paragraph={`${event.startDate} ${event.endDate ? ('to ' + event.endDate) : ''}`} />
      <EventContainer>
        <div className="event-featured-image">
          <GatsbyImage image={event.featuredImage.gatsbyImageData} alt={event.featuredImage.title} />
        </div>
        <div className="container">
          <div className="event-content">
            <div className="event-meta">
              {event.eventCity && (
                <p>
                  <span>Location:</span>
                  <a href={`/${event.eventGoogleMapUrl}`} target="_blank" rel="noopener noreferrer">{event.eventAddress ? event.eventAddress : ''}{event.eventCity ? (' ' + event.eventCity) : ''}{event.eventState ? (', ' + event.eventState) : ''}{event.eventZipcode ? (', ' + event.eventZipcode) : ''}</a>
                </p>
              )}
              {event.eventUrl && (
                <p>
                  <span>Website:</span><a href={`${event.eventUrl}`} target="_blank" rel="noopener noreferrer">{event.eventUrl}</a>
                </p>
              )}
            </div>

            <div className="event-body">
              {event.eventIntroParagraph && (
                <div className="intro-container">
                  <p>{event.eventIntroParagraph.eventIntroParagraph}</p>
                  <div className="intro-photo">
                    <StaticImage
                      src="../images/home-photo-frame-back.png"
                      width={160}
                      height={160}
                      alt=""
                      className="bio-frame-back"
                    />
                    <GatsbyImage image={event.eventSpeakerImage.gatsbyImageData} alt={event.eventSpeakerImage.title} width={160} height={160} className="bio-frame-photo" />
                    <StaticImage
                      src="../images/home-photo-frame-front.png"
                      width={160}
                      height={160}
                      alt=""
                      className="bio-frame-front"
                    />
                  </div>
                </div>
              )}

              {event.body && renderRichText(event.body, options)}
            </div>

            <div className="event-sharing">
              <p>Share This Event</p>
              <div className="sharing-buttons">
                <a target="_blank" rel="noopener noreferrer" href={`https://twitter.com/share?text=${event.title}&url=https://fintechlegal.io/events/${event.slug}`}>
                  <img src={Twitter} alt="Twitter Sharing" />
                </a>
                <a target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/sharer.php?u=https://fintechlegal.io/events/${event.slug}`}>
                  <img src={Facebook} alt="Facebook Sharing" />
                </a>
                <a href={`mailto:?subject=Check out this FinTech Law Event&body=Check out this FinTech Law Event - https://fintechlegal.io/events/${event.slug}`}
                  title="Share FinTech Law Event by Email">
                  <img src={Email} alt="Linkedin Sharing" />
                </a>
              </div>
            </div>

            <div className="view-more-btn">
              <a href='/events/' className="btn btn-large">View More Events</a>
            </div>
          </div>
        </div>
        
      </EventContainer>
      <NewsletterCTA />
    </main>
  )
}

export default Event

export const Head = (pageContext) => {
  return (
    <>
      <Seo 
        key={pageContext.pageContext.event.id} 
        title={pageContext.pageContext.event.seoTitle}
        description={pageContext.pageContext.event.seoTitle}
        image={pageContext.pageContext.event.seoImage.url}
      />
    </>
  )
}